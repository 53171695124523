import React from 'react';
import { graphql, Link } from 'gatsby';

const IndexPage = ({ data }) => {
  const nodes = data.allContentJson.edges;
  return (
    <ul>
      {nodes.map(({ node }, index) => (
        <li key={index}>
          <Link to={node.fields.slug}>{node.title}</Link>
        </li>
      ))}
    </ul>
  );
};

export const query = graphql`
  query {
    allContentJson {
      edges {
        node {
          title
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
